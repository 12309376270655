import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, DropdownButton, Dropdown, FormControl, Form, Button } from "react-bootstrap";
import ltp from "../assets/images/ltp.png"
import checkpoint from "../assets/images/checkpoint.png";
import confirm from "../assets/images/confirm.png";
import confirmation from "../assets/images/confirmation.png";
import preauth from "../assets/images/pre-auth.png";
import amountstack from "../assets/images/amountstack.png";
import warningyellow from "../assets/images/warning-yellow.png"
import warning from "../assets/images/warning.png";
import { Link } from "react-router-dom";
// import { Particle,  Footer } from "../components/";
import footer from "../components/Footer";
import {
    busd_addr, factory_addr, litePad_addr, rewardToken_addr, staking_addr, ticketConsumer_addr,
    tokenForSale_addr
} from "../contract/addresses"
import Web3Modal from 'web3modal'
// import {} from "../contract/CrowdSale.json"
// import {} from "../contract/Factory.json"
import ZPadAbi from "../contract/LitePad.json"
// import {} from "../contract/RewardToken.json"
import StakingAbi from "../contract/Staking.json"
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core";
import detectEthereumProvider from '@metamask/detect-provider'
import DashboardHeader from "../components/DashboadHeader";
import Footer from "../components/Footer";
import Particle from "../components/Particle";


function Staking(props) {



    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal
    } = useWeb3React();

    const [totalzpadToken, setTotalZpadToken] = useState(0)
    const [stakevalue,setStakevalue] = useState(0);
    const [unStakeValue, setUnStakeValue] = useState(0)
    const [staketype,setStaketype] = useState('stake');
    const [totalToken, setTotalToken] = useState()
    const [totalbalance, setTotalBalance] = useState(0)
    const [stakersNo, setStakersNo] = useState(0)
    const [userApy, setUserApy] = useState("105%")
    const [userReward, setUserReward] = useState(0)
    const [userUnstakedValue, setUserUnstakedValue] = useState(0)
    const [authorization, setAuthorization] = useState("")
    const [Confirmation, setConfirmation] = useState("")
    const [confirmed, setConfirmed] = useState("")
    const [ethAddress, setEthAddress] = useState("0")
    const [check, setCheck] = useState(false)
    const [userToken, setUserToken] = useState("0")



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false)
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [error, setError] = useState()
    const [msgHandling, setMsgHandling] = useState()
    
    const [bronze, setBronze] = useState(0)
    const [silver, setSilver] = useState(0)
    const [gold, setGold] = useState(0)
    const [tokenError, setTokenError]= useState()
    const [mystate,setMystate] = useState(0);
    // fazal 
    const [isType,setIsType]= useState('stake')
    // console.log("chainId", chainId)

    const [scroll, setScroll] = useState(0)

    const top =  () => {
        window.scrollTo({
            top: scroll,
          });
      };

      const top1 =  () => {
        window.scrollTo({
            top:12000 
          });
      };

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
          } catch (e) {
            console.log("loadProvider default: ", e);
          }
      };


      const getEther = async () => {
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        const provider = new ethers.providers.Web3Provider(connection);
        let balance = await provider.getBalance(account)

        setEthAddress(ethers.utils.formatEther(balance))
        
    }
    
   
      
      


    
      const loadTotalStake = async () => {
        try{
            let signer = await loadProvider()
            let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
            let totalStakedValue = await stakingContract.totalStakedValue()
            let token = await ethers.utils.formatUnits(totalStakedValue.toString(),18)
            setTotalToken(token)
        }catch(e){
            console.log(e)
        }
        }


        // function to insert token to the smart contract
        const Stake = async () => {
            try{
                setAuthorization("Pre-authorization")
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                let getBronze = await stakingContract.bronze()
                let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
                let allowanceCheck = await ZPadContract.allowance(account, staking_addr)
                
                let decimalsUnit = await ZPadContract.decimals();
                let _value = await ethers.utils.parseUnits(stakevalue,decimalsUnit)
                if(allowanceCheck.toString() < getBronze){
                    setConfirmation("Confirmation")
                    let approve = await ZPadContract.approve(staking_addr, _value)
                    let approveTx = await approve.wait()
                    if(approveTx && approveTx.blockNumber){
                        setMystate(stakevalue)
                        setMsgHandling("Staking")
                        let stake = await stakingContract.stake(ethers.utils.parseUnits(stakevalue,decimalsUnit))
                        let tx = await stake.wait()
                        totalZpadToken()
                        setConfirmed("Confirmed")
                        // totalBalance()
                        setStakevalue(0)
                        Stakers()
                        loadTotalStake()

                    }
                    else{
                        console.log("error")
                    }
                }else{
                    // console.log("errorr")
                    setConfirmation("Confirmation")
                    setMsgHandling("Staking")
                        let stake = await stakingContract.stake(ethers.utils.parseUnits(stakevalue,decimalsUnit))
                        let tx = await stake.wait()
                        // console.log("tx2", tx)
                        totalZpadToken()
                        setConfirmed("Confirmed")
                        setStakevalue(0)
                        Stakers()
                        loadTotalStake()

                }
                
            }
            catch(e){
                setMsgHandling(e)
                // handleShow()
                handleShow1()
                setError(1)
                // console.log("error: ",e)
            }
        }

        const totalZpadToken = async () => {
            try{
              let signer = await loadProvider()
              let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
              let balanceOf = await ZPadContract.balanceOf(account)
              let decimalsUnit = await ZPadContract.decimals();
              let token = await ethers.utils.formatUnits(balanceOf.toString(),18)
              
              setTotalZpadToken(parseInt(token).toString())
              // console.log("balance>>",  token)
            }
            catch(error){
                console.log(error)
            }
             
          }

        // console.log("msgHandling", msgHandling)

        
        // let short = msgHandling.slice(0, 20)+"..." + msgHandling.slice(len-5, len-1)
        // console.log("short", short)

        // console.log("bronze", msgHandling)

        // This function is used to call Stake function
        const Staking = (event) => {
            Stake()
            event.preventDefault()
        }

        

        // This Function is used to lock Maximum Token

        const MaxStake = async () => {
            try{
              let signer = await loadProvider()
              let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
              let balanceOf = await ZPadContract.balanceOf(account)
              let decimalsUnit = await ZPadContract.decimals();
              let token = await ethers.utils.formatUnits(balanceOf.toString(),decimalsUnit)
              


              setStakevalue(parseInt(token).toString())
              // console.log("balance>>",  token)
            }
            catch(error){
                console.log(error)
            }
             
          }

          const userBalance = async () => {
            try{
              let signer = await loadProvider()
              let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
              let balanceOf = await ZPadContract.balanceOf(account)
              let decimalsUnit = await ZPadContract.decimals();
              let token = await ethers.utils.formatUnits(balanceOf.toString(),decimalsUnit)

              setUserToken(parseInt(token).toString())
              // console.log("balance>>",  token)
            }
            catch(error){
                console.log(error)
            }
             
          }

        // This Function is used for unStake Token from SmartToken

        const unStake = async () => {
            try{
                
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                let token = await ethers.utils.parseUnits((unStakeValue).toString(),18)
                
                // console.log(token)
                let unStake = await stakingContract.unStake(token)
                // console.log("unStake>>>>>>>>>>", unStake)
                setAuthorization("Unstake")
                let tx = await unStake.wait()
                totalZpadToken()
                setConfirmed("Unstake_Confirmed")
                setUnStakeValue(0)
            }
            catch(e){
                console.log(e)
            }
        }
        
        // console.log("unStakeValue", unStakeValue)

        const MaxUnStake = async () => {
            try{
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
             //   let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
            //   let decimalsUnit = await ZPadContract.decimals();
            //   console.log("decimalsUnit", decimalsUnit)
                let getUserStakedValue = await stakingContract.getUserStakedValue(account)
                let token = await ethers.utils.formatUnits(getUserStakedValue.toString(),18)
                // console.log("token>>", token)
                setUnStakeValue(parseInt(token).toString())
                // setUnStakeValue(Math.floor(token))

            //     let ZPadContract = new ethers.Contract(litePad_addr, ZPadAbi, signer)
            //   let balanceOf = await ZPadContract.balanceOf(account)
            //   let decimalsUnit = await ZPadContract.decimals();
            //   let token = await ethers.utils.formatUnits(balanceOf.toString(),decimalsUnit)
            }
            catch(e){
                console.log(e)
            }
        }


        const unStaking = (event) => {
            unStake()
            event.preventDefault()
        }

        // This function is used to get unStaked Value

        const getUnstakedValue = async () => {
            try{
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                let getUserStakedValue = await stakingContract.getUnstakedValue(account)
               // let token = ethers.utils.formatEther(getUserStakedValue.toString())
                let token = await ethers.utils.formatUnits(getUserStakedValue.toString(),18)
                setUserUnstakedValue(token)
                // console.log(getUserStakedValue.toString())
            }
            catch(e){
                console.log(e)
            }
        }
        // console.log("userUnstakedValue",userUnstakedValue)


        // This is functrion is used for pull Rewards
        const Reward = async () => {
            try{
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                setAuthorization("Withdraw")
                if(totalbalance == 0){
                    return
                }
                else{
                    let withdrawRewards = await stakingContract.withdrawRewards()
                    await withdrawRewards.wait()
                    setConfirmed("Withdraw_Confirmed")
                // console.log("withdrawRewards", withdrawRewards)
    
                }
                
            }
            catch(e) {
                console.log(e)
            }
        }

        // This function is used for Claculate Panding Reward
        const calcPendingReward = async () => {
            try{
                let signer = await loadProvider()
            let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
            let calcPendingRewards = await stakingContract.calcRewards(account)
            let PendingRewards = await ethers.utils.formatUnits(calcPendingRewards.toString(),18)
            let count = Number(PendingRewards)
            count.toFixed(3)
            setUserReward(count.toFixed(4))
            }
            catch(e){
                console.log(e)
            }
        } 
        // calcPendingReward()



        // This function is used to get all token to the user
        const totalBalance = async () => {
            try{
                // console.log("token>>")
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                let getUserStakedValue = await stakingContract.getUserStakedValue(account)
               // let token = ethers.utils.formatEther(getUserStakedValue.toString())
                let token = await ethers.utils.formatUnits(getUserStakedValue.toString(),18)
                setTotalBalance(token)
                // console.log("getUserStakedValue", token)
            } 
            catch(e){
                console.log(e)
            }
        }

        // This Function is used to get staking and unstaking Events from smart contract

        const Event = async () => {
            try{
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                  stakingContract.on("eve_staked", (amount) => {
                    
                    loadTotalStake()
                    totalBalance()
                }
                ) 
                stakingContract.on("eve_Unstaked", (amount) => {
                  
                    loadTotalStake()
                    totalBalance()
                    getUnstakedValue()
                    
                    // console.log("amount>>", amount.toString())
                }
                ) 
            }
            catch(e){
                console.log(e)
            }
        }

        // This Function is Used For No Of Stakers

        const Stakers = async () => {
            try{
                let signer = await loadProvider()
                let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
                let staker = await stakingContract.noOfStakers()
    
                if(staker <=0 ){
                    setStakersNo("NA")
                }
                else{
    
                    setStakersNo(staker.toString())
                }
                // console.log("getAPY", staker.toString())
            }
            catch(e){
                console.log(e)
            }

           
            // setStakersNo(staker.toString())
            // if(stakersNo == null || 0) {
            //     setStakersNo("hjhj")
            // }
            // console.log("staker", staker.toString())
        }

        // const APY = async () => {
        //     let signer = await loadProvider()
        //     let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
        //     let getAPY = await stakingContract.getAPY()
        //     if(getAPY <=0 ){
        //         setUserApy("NA")
        //     }
        //     else{

        //         setUserApy(getAPY.toString())
        //     }
        //     console.log("getAPY", getAPY.toString())
        // }
        // Stakers()

        const Tiers = async () => {
           try{
            let signer = await loadProvider()
            let stakingContract = new ethers.Contract(staking_addr, StakingAbi, signer)
            let bronze = await stakingContract.bronze()
            setBronze(Math.floor(ethers.utils.formatEther(bronze.toString())))
           }
           catch(e){
               console.log(e)
           }
        }
        

        useEffect(() => {
            (async () => {
                if (account) {
                    try {
                        // loadTotalStake()
                        getEther()
                        Event()
                        totalBalance()
                        getUnstakedValue()
                        loadTotalStake()
                        Stakers()
                        Tiers()
                        userBalance()
                        // Stakers()
    
                    } catch (error) {
                        console.log(error)
                    }
                }
            })()
        }, [account]);

        useEffect(() => {
            (async () => {
                if (account) {
                    try {
                        totalZpadToken()
    
                    } catch (error) {
                        console.log(error)
                    }
                }
            })()
        }, [account,totalzpadToken]);


        // useEffect(() => {
        //     (async () => {
                
        //             try {
        //                 loadTotalStake()
        //                 Stakers()
        //                 Tiers()
        //             } catch (error) {
        //                 console.log(error)
        //             }
                
        //     })()
        // }, []);

        // console.log("userReward",userReward)
        
        useEffect(() => {
            (async () => {
                if (account && totalbalance > 0) {
                    try {
                        const interval = setInterval(() => {
                                calcPendingReward();
                                // console.log("hhh")
                              }, 15000);
                              return () => clearInterval(interval);
                        
    
                    } catch (error) {
                        console.log(error)
                    }
                }
            })()
        }, [account, totalbalance]);




    return (

        <>


            
                

                <Particle />
            <div className="text" >

                <DashboardHeader />
                {/* {props.header} */}

                <Container className="mb-5 mt-5" >

                    <Row className="align-items-center mb-5">

                        <Col lg={3} md={12}>


                            {/* <DropdownButton  title="Staking" className="staking-dropdown">
                                <Dropdown.Item href="/unstaking">Unstaking</Dropdown.Item>
                            </DropdownButton> */}

                            <DropdownButton title={isType == "stake" ? "Staking" : isType == "unstaking" ? "Unstaking" : isType == "withdraw" ? "Withdraw" : "Staking"} className="staking-dropdown">
                                {
                                    isType == "stake" ? "" :
                                        <Dropdown.Item href="#" onClick={(e) => setIsType("stake")}>Staking</Dropdown.Item>
                                }
                                {
                                    isType == "unstaking" ? "" :
                                        <Dropdown.Item href="#" onClick={(e) => setIsType("unstaking")}>Unstaking</Dropdown.Item>
                                }

                                {
                                    isType == "withdraw" ? "" :
                                        <Dropdown.Item href="#" onClick={(e) => setIsType("withdraw")}>Withdraw</Dropdown.Item>
                                }

                            </DropdownButton>


                        </Col>

                        <Col lg={3} md={4}>

                            <div className="ido-box ido-small">

                                <p className="f-bold text-center">Number Of Stakers</p>
                                {stakersNo > 0 ? (
                                    <h4 className="soon text-center mt-2">{stakersNo}</h4>
                                ) : (
                                    <h4 className="soon text-center mt-2">{("NA")}</h4>
                                )}

                            </div>

                        </Col>

                        <Col lg={3} md={4}>

                            <div className="ido-box ido-small">

                                <p className="f-bold text-center">Total litepad Stacked</p>
                                {totalToken > 0 ? (
                                    <h4 className="soon text-center mt-2">{totalToken}</h4>
                                ) : (
                                    <h4 className="soon text-center mt-2">{("0")}</h4>
                                )}

                            </div>

                        </Col>

                        <Col lg={3} md={4}>

                            <div className="ido-box ido-small">

                                <p className="f-bold text-center">APY</p>
                                <h4 className="soon text-center mt-2">{userApy}</h4>

                            </div>

                        </Col>

                        <Col lg={3}>
                            <button onClick={async (event) => {
                                const provider = await detectEthereumProvider()
                                provider.sendAsync({
                                    method: 'metamask_watchAsset',
                                    params: {
                                        "type": "ERC20",
                                        "options": {
                                            "address": rewardToken_addr,
                                            "symbol": "LPT",
                                            "decimals": 18,
                                        },
                                    },
                                }, (err, added) => {
                                    // console.log('provider returned', err, added)
                                    if (err || 'error' in added) {

                                        setTokenError("There was a problem adding the token.")
                                        return
                                    }
                                    setTokenError("Token added!")
                                })
                            }} className="btn-custom primary-btn mt-2">Import Token
                            </button>

                        </Col>

                    </Row>

                    <Row className="align-items-center">

                        <Col lg={8}  sm={12} md={12} className="text-center">
                            <img src={ltp}  width="100%" />
                        </Col>

                        <Col lg={4}  sm={12} md={12}>

                            <div className="ido-box">

                                <div className="staked">
                                    <h4>Total Litepad Token</h4>
                                    <h2>{totalzpadToken}</h2>
                                    {/* {console.log("totalbalance", totalbalance)} */}
                                </div>

                                <div className="staked">
                                    <h4>Staked</h4>
                                    <h2>{totalbalance}</h2>
                                </div>

                                <div className="staked">
                                    <h4>Unstaked</h4>
                                    <h2>{userUnstakedValue}</h2>
                                </div>

                                <div className="staked">
                                    <h4>Reward</h4>
                                    <h2>{userReward}</h2>
                                </div>

                                {isType == "stake" ? (
                                    <Form className="text-center mt-3">

                                        <Form.Group className="mb-3 max-staked" controlId="formBasicCheckbox">
                                            <Form.Control type="text" value={stakevalue} placeholder="Stake Amount" onChange={(e) => setStakevalue(e.target.value)} />
                                            <Button onClick={MaxStake} className="">
                                                Max
                                            </Button>
                                        </Form.Group>
                                        {/* <button type="submit" onClick={Staking} className="primary-btn">
                                    Stake
                                </button> */}

                                        {check == false ? (<button onClick={(e)=>{
                                            setScroll(window.scrollY)
                                            top1(e.preventDefault())}} type="submit"  className="primary-btn">
                                            Stake
                                        </button>) : (<button onClick={Staking} type="submit" className="primary-btn">
                                            Stake
                                        </button>)}
                                    </Form>
                                ) : null}

                                {isType == "unstaking" ? (
                                    <Form className="text-center mt-3">

                                        <Form.Group className="mb-3 max-staked" controlId="formBasicCheckbox">
                                            <Form.Control type="text" placeholder="Stake Amount" value={unStakeValue} onChange={(e) => setUnStakeValue(e.target.value)} />
                                            <Button onClick={MaxUnStake} className="">
                                                Max
                                            </Button>
                                        </Form.Group>
                                        <button onClick={unStaking} type="submit" className="primary-btn">
                                            Unstake
                                        </button>
                                    </Form>
                                ) : null}

                               {isType == "withdraw" ? (
                                    <div className="text-center mt-3">
                                         <button onClick={Reward} type="submit" className="primary-btn">
                                            Withdraw
                                        </button>
                                    </div>
                                ) : null}


                            </div>

                        </Col>



                    </Row>
                </Container>




                {isType == "stake" ? (
                    <Container className="py-5">

                        <div className='section-title center'>

                            <h5>Stake Your Litepad</h5>
                            <h2>Stake</h2>

                        </div>

                        <div class="roadmap">

                            <div class="roadmap-item circle-active">

                                <div class="roadmap-circle">
                                    <img src={checkpoint} />
                                </div>

                                <p>Checkpoint</p>

                            </div>

                            <hr class="roadmap-hr" />

                            {/* <div class="roadmap-item">

                    <div class="roadmap-circle">
                    <img src={amountstack}/>
                    </div>

                    <p>Amount to Stake</p>

                </div> */}

                            {stakevalue > 0 || mystate > bronze ?
                                (<div class="roadmap-item circle-active">


                                    <div class="roadmap-circle">
                                        <img src={amountstack} />

                                    </div>

                                    <p>Amount to Stake</p>

                                </div>) : (<div class="roadmap-item">


                                    <div class="roadmap-circle">
                                        <img src={amountstack} />

                                    </div>

                                    <p>Amount to Stake</p>

                                </div>)}


                            <hr class="roadmap-hr" />

                            {/* <div class="roadmap-item">

                    <div class="roadmap-circle">
                    <img src={preauth}/>
                    </div>

                    <p>Pre-authorization</p>

                </div> */}

                            {authorization == "Pre-authorization" ? (<div class="roadmap-item circle-active">

                                <div class="roadmap-circle">
                                    <img src={preauth} />
                                </div>

                                <p>Pre-authorization</p>

                            </div>) : (<div class="roadmap-item">

                                <div class="roadmap-circle">
                                    <img src={preauth} />
                                </div>

                                <p>Pre-authorization</p>

                            </div>)}

                            <hr class="roadmap-hr" />

                            {/* <div class="roadmap-item">

                    <div class="roadmap-circle">
                    <img src={confirm}/>
                    </div>

                    <p>Confirm</p>

                </div> */}

                            {Confirmation == "Confirmation" ?
                                (<div class="roadmap-item circle-active">

                                    <div class="roadmap-circle">
                                        <img src={confirm} />
                                    </div>

                                    <p>Confirm</p>

                                </div>) : (<div class="roadmap-item">

                                    <div class="roadmap-circle">
                                        <img src={confirm} />
                                    </div>

                                    <p>Confirm</p>

                                </div>)}

                            <hr class="roadmap-hr" />

                            {/* <div class="roadmap-item">

                        <div class="roadmap-circle">
                        <img src={confirmation}/>
                        </div>

                        <p>Confirmation</p>

                    </div> */}

                            {confirmed == "Confirmed" ?
                                (<div class="roadmap-item circle-active">

                                    <div class="roadmap-circle">
                                        <img src={confirmation} />
                                    </div>

                                    <p>Confirmation</p>

                                </div>) : (<div class="roadmap-item">

                                    <div class="roadmap-circle">
                                        <img src={confirmation} />
                                    </div>

                                    <p>Confirmation</p>

                                </div>)}



                        </div>


                        <div class="section-title">

                            <h2>The following conditions must be met before proceeding</h2>

                        </div>

                        <div className="spacing"></div>

                        <div className="ido-box" style={{ background: "transparent" }}>

                            <div className="d-flex mb-5 flex-xs-wrap justify-content-center">

                                <div className="conditions">
                                    {account ? (<div>
                                        <span className="conditions-met">
                                            <h4>Connected with MetaMask</h4>
                                            <span className="tick-enable"><i class="fa-solid fa-check"></i></span>
                                        </span>

                                        <p>If not connected, click the "Connect Wallet" button in the top right corner
                                        </p>
                                    </div>) : (<div>
                                        <span className="conditions-met">
                                            <h4>Connected with MetaMask</h4>
                                            <span className="tick-enable">
                                                {/* <i class="fa-solid fa-check"></i> */}
                                            </span>
                                        </span>

                                        <p>If not connected, click
                                            the "Connect Wallet"
                                            button in the top right
                                            corner
                                        </p>
                                    </div>)}



                                </div>

                                <div className="conditions">

                                    {userToken > 0 ? (<div>
                                        <span className="conditions-met">
                                            <h4>LITEPAD Available to Deposit</h4>
                                            <span className="tick-enable">
                                                <i class="fa-solid fa-check"></i>
                                            </span>
                                        </span>

                                        <p>
                                            {userToken}
                                        </p>
                                    </div>) : (<div>
                                        <span className="conditions-met">
                                            <h4>LITEPAD Available to Deposit</h4>
                                            <span className="tick-enable tick-disble"><i class="fa-solid fa-check"></i></span>
                                        </span>

                                        <p>
                                            {userToken}
                                        </p>
                                    </div>)}

                                </div>

                                <div className="conditions">

                                    {ethAddress > 0 ? (<div>
                                        <span className="conditions-met">
                                            <h4>BNB Available in
                                                Wallet</h4>
                                            <span className="tick-enable">
                                                <i class="fa-solid fa-check"></i>
                                            </span>
                                        </span>

                                        <p>
                                            {ethAddress}
                                            {/* {parseFloat(formatEther(ethAddress)).toPrecision(4)} */}
                                        </p>
                                    </div>) : (<div>
                                        <span className="conditions-met">
                                            <h4>BNB Available in
                                                Wallet</h4>
                                            <span className="tick-enable tick-disble"><i class="fa-solid fa-check"></i></span>
                                        </span>

                                        <p>
                                            {parseFloat(ethAddress).toFixed(4)}
                                        </p>
                                    </div>)}

                                </div>



                            </div>

                            <Form>
                                <div class="custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="defaultUnchecked" onChange={(e) => setCheck(e.target.checked)} />
                                    <label class="custom-control-label" for="defaultUnchecked">I have read the Terms and Conditions</label>
                                </div>
                            </Form>

                        </div>

                        <div className="text-center my-5">
                        <button className="btn-custom secondary-btn" onClick={(e)=>top()}>Next</button>
                        </div>

                    </Container>
                ) : null}

                {isType == "unstaking" ? (
                    <Container className="py-5">

                        <div className='section-title center'>

                            <h5>Unstake Your Litepad</h5>
                            <h2>Stake</h2>

                        </div>

                        <div class="roadmap">

                            <div class="roadmap-item circle-active">

                                <div class="roadmap-circle">
                                    <img src={warning} />
                                </div>

                                <p>Warning</p>

                            </div>

                            <hr class="roadmap-hr" />

                            <div class="roadmap-item">

                                <div class="roadmap-circle">
                                    <img src={checkpoint} />
                                </div>

                                <p>Checklist</p>

                            </div>

                            <hr class="roadmap-hr" />

                            <div class="roadmap-item">

                                <div class="roadmap-circle">
                                    <img src={amountstack} />
                                </div>

                                <p>Amount to Stake</p>

                            </div>

                            <hr class="roadmap-hr" />

                            <div class="roadmap-item">

                                <div class="roadmap-circle">
                                    <img src={confirm} />
                                </div>

                                <p>Initialize Unstake</p>

                            </div>

                            <hr class="roadmap-hr" />

                            <div class="roadmap-item">

                                <div class="roadmap-circle">
                                    <img src={confirmation} />
                                </div>

                                <p>Confirmation</p>

                            </div>



                        </div>



                        <div className="ido-box" style={{ background: "transparent" }}>

                            <div className="unstaking-warn">

                                <img src={warningyellow} />
                                <p>After Unstaking, you must wait 7 days before you can withdraw your LITEPAD and rewards.

                                    The amount of tokens you Unstake will not count towards your tier level for upcoming Projects.</p>

                            </div>

                        </div>

                        <div className="text-center my-5">
                            <Link to={'/'} className="secondary-btn">Next</Link>
                        </div>

                    </Container>
                ) : null}

                <Footer />

            </div>
        </>

    )

}

export default Staking;